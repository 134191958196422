<template>
  <div class="b-table">
    <div class="table-wrapper has-mobile-cards">
      <table class="table cross-table is-striped is-narrow has-sticky-header">
        <thead>
          <tr>
            <th>
              <div class="th-wrap is-centered">
                <span class="is-relative"></span>
              </div>
            </th>
            <th :colspan="data.colVariable.length">
              <div class="th-wrap is-centered">
                <span class="is-relative"> {{ data.colVariable }}</span>
              </div>
            </th>
          </tr>
          <tr>
            <th
              v-for="(field,index) in data.fields"
              :key="index"
            >
              <div class="th-wrap is-right">
                <span v-if="field.label" class="is-relative"> {{ field.label }}</span>
                <span v-else class="is-relative"> {{ data.rowVariable }}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row,index) in data.data"
            :key="index"
          >
            <td
              v-for="(field,index2) in data.fields"
              :key="index2"
              class="has-text-right"
            >
              {{ row[field.field] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CrossTable',
  props: {
    data: {
      type: Object,
      default: null
    }
  }
}
</script>

<style scoped>
  .cross-table {
    max-width: 860px;
    overflow-x: auto;
    display: block;
  }
</style>
